
import Http from './Http'

export default {
    async getMatchMotm(match_id) {
        return await Http.get(`/games/motm/match/${match_id}/all`)
    },
    async getMatchScorePrediction(match_id) {
        return await Http.get(`/games/gts/match/${match_id}`)
    },
}


