<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Teams from '@/services/Teams';
    import Games from '@/services/Games';
    import JsonCSV from 'vue-json-csv';
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/pie";

    import "echarts/lib/component/legend";
    import "echarts/lib/component/title";
    import "echarts/lib/component/tooltip";
    import "echarts/lib/component/polar";
    import "echarts/lib/component/toolbox";
    import "echarts/lib/component/grid";


    export default {
        components: { Layout, PageHeader, "v-chart": ECharts, "downloadCsv": JsonCSV },
        page: {
            title: "Guess the score",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                title: "Guess the score",
                items: [
                    {
                        text: "Fan Experience",
                    },
                    {
                        text: "Guess the score",
                    },

                ],
                selectedMatch: '',
                matches: [],
                sortedMatches: [],
                sortedPredictions: [],
                sortedPredictionsL: 0,
                homeTeamLogo: null,
                awayTeamLogo: null,
                homeTeamName: null,
                awayTeamName: null,
                homeTeamScore: null,
                awayTeamScore: null,
                date_formatted: null,
                totalVotes: null,
                pieChartData: null,
                jsonPredictionsData: [],
                filenameCSV: 'predictions.csv',
                keyLength: [],
                keyVal: [],
            };
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        async created() {
            this.matchesData()
        },
        methods: {
            async getMatches(type){
                try {
                    const response = await Teams.getFixtures(type)
                    if(response.data){
                        return response.data;
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async matchesData(){
                var upcomingMatches = await this.getMatches('UPCOMING');
                var pastMatches = await this.getMatches('PAST');
                var pastMatchTemp = [];
                var upcomingMatchTemp = [];

                if(upcomingMatches.fixtures.length > 0){
                    upcomingMatchTemp = upcomingMatches.fixtures;
                }
                if(pastMatches.fixtures.length > 0){
                    pastMatchTemp = pastMatches.fixtures;
                }
                this.matches = upcomingMatchTemp.concat(pastMatchTemp);
                this.sort_matches();
            },

            getMatchScorePrediction(){

                if(this.sortedMatches[this.selectedMatch]){
                    this.homeTeamLogo = this.sortedMatches[this.selectedMatch][0].home_team_logo;
                    this.awayTeamLogo = this.sortedMatches[this.selectedMatch][0].away_team_logo;
                    this.homeTeamName = this.sortedMatches[this.selectedMatch][0].home_team_name;
                    this.awayTeamName = this.sortedMatches[this.selectedMatch][0].away_team_name;
                    this.homeTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_home;
                    this.awayTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_away;
                    this.date_formatted = this.sortedMatches[this.selectedMatch][0].date_formatted;
                }else{
                    this.homeTeamLogo = this.awayTeamLogo =  this.awayTeamLogo = this.homeTeamName = this.awayTeamName = '';
                }

                Games.getMatchScorePrediction(this.selectedMatch).then(response => {
                    if(response.data.data){
                        this.editView(response.data.data)
                    }

                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.sortedPredictionsL = 0;
                    this.totalVotes = null;
                })
            },

            editView(predictionData){
                if(predictionData.length > 0){

                    var filename = this.sortedMatches[this.selectedMatch][0].home_team_short+'_'+this.sortedMatches[this.selectedMatch][0].away_team_short+'_'+this.sortedMatches[this.selectedMatch][0].date_time+'.csv'
                    this.filenameCSV = filename.replace(/ /g,"_");

                    this.jsonPredictionsData = predictionData
                    this.totalVotes = predictionData.length

                    this.sortedPredictions = predictionData.reduce(function (r, a) {
                        var scoreKey = a.home_team_score_guess+':'+a.away_team_score_guess;
                        r[scoreKey] = r[scoreKey] || [];
                        r[scoreKey].push(a);
                        return r;
                    }, Object.create(null));


                    this.sortedPredictionsL = Object.keys(this.sortedPredictions).length
                    var arrValues = [];

                    if(this.sortedPredictionsL > 0){

                        const keys = Object.keys(this.sortedPredictions);

                        keys.forEach((key) => {
                            var keyLength = this.sortedPredictions[key].length;
                            var keyVal = this.sortedPredictions[key][0].home_team_score_guess + ':' + this.sortedPredictions[key][0].away_team_score_guess;
                            arrValues.push({
                                value: keyLength, name : keyVal
                            })
                        });


                        this.pieChartData = {
                            visualMap: {
                                show: false,
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c} ({d}%)'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left',
                                data: keys,
                                textStyle: {
                                    color: '#999',
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                }
                            },
                            color: ['#f46a6a', '#34c38f', '#50a5f1', '#f1b44c', '#556ee6'],
                            series: [
                                {
                                    name: 'Total per prediction',
                                    type: 'pie',
                                    radius: '80%',
                                    center: ['50%', '60%'],
                                    data: arrValues,
                                    itemStyle: {
                                        emphasis: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ],
                            fontSize: 18
                        };

                    }else{
                        this.pieChartData = null;
                    }
                }
            },
            sort_matches(){
                this.sortedMatches = this.matches.reduce(function (r, a) {
                    r[a.match_id] = r[a.match_id] || [];
                    r[a.match_id].push(a);
                    return r;
                }, Object.create(null));
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4 d-flex justify-content-center">

                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="homeTeamLogo != null" :src="homeTeamLogo" width="50px" :alt="homeTeamName">
                                    <p class="font-size-16">{{homeTeamName}}</p>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <div class="form-row form-group mb-3" :class="(matches.length <= 0) ? 'placeholder-glow' :''">
                                        <label class="col-md-12 text-center col-form-label">Select Match</label>
                                        <select class="form-select" :class="(matches.length <= 0) ? 'placeholder' :''" v-model="selectedMatch" @change="getMatchScorePrediction()" id="selectMatch">
                                            <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                                                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
                                            </option>
                                        </select>
                                        <div class="row mt-4 w-100">
                                            <div class="col-md-12 text-center " v-if="homeTeamScore != null">
                                                <span class="me-5 text-strong font-size-34">{{homeTeamScore}}</span> : <span class="ms-5 text-strong font-size-34">{{awayTeamScore}}</span>
                                                <p class="p-1 text-strong text-muted">{{date_formatted}}</p>
                                                <p class="m-0" v-if="totalVotes != null">{{totalVotes}} votes in total</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="awayTeamLogo != null" :src="awayTeamLogo" width="50px" :alt="awayTeamName">
                                    <p class="font-size-16">{{awayTeamName}}</p>
                                </div>
                        </div>
                    </div>
                </div>


                <div class="card" v-if="sortedPredictionsL > 0">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <h5>Predictions Chart</h5>
                                </div>
                                <v-chart :options="pieChartData" autoresize />
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <h5>Correct Score Predictions</h5>
                                </div>
                                <div class="col-md-12" v-if="sortedPredictions[homeTeamScore+':'+awayTeamScore]">
                                    <ol>
                                       <div v-for="(item) of sortedPredictions[homeTeamScore+':'+awayTeamScore]" :key="item.customer_id">
                                           <li v-if="item.customer_first_name">
                                               <router-link :to="{ path: `/customers/profile/${item.customer_id}`}">
                                                    {{item.customer_first_name}} {{item.customer_last_name}}
                                               </router-link>
                                           </li>
                                           <li v-else>{{item.email}}</li>
                                       </div>
                                    </ol>
                                </div>
                                <div v-else>
                                    <p>No correct predictions</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <download-csv :data="jsonPredictionsData" :name="filenameCSV" class="btn btn-success waves-effect waves-light"><i class="uil uil-download-alt me-2"></i> Download Predictions </download-csv>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
